<template>
  <div class="monitoring">
    <!--监测设备查询-->
    <p class="head">监测设备查询</p>
    <!--监测设备具体查询搜索-->
    <div class="search">
      <el-input v-model="searchData" clearable placeholder="设备名称"></el-input>
      <el-button @click="searchBtn">查询</el-button>
    </div>
    <!--设备型号管理和新增设备型号-->
    <div class="title">
      <p>设备型号管理</p>
      <button @click="createEquipModel">新增设备型号</button>
    </div>
    <!--设备型号表格-->
    <el-table v-loading="loading" :data="equipModelData" border style="width: 100%;margin-top: 10px"
              table-layout="auto">
      <el-table-column label="序号" prop="" width="100px">
        <template #default="scope">
          {{ (pageIndex - 1) * pageSize + scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="PID别名" prop="name"/>
      <el-table-column label="PID" prop="code"/>
      <el-table-column label="设备编号(平台内唯一编号)" prop="uuid"/>
      <el-table-column label="备注" prop="remark"/>
      <el-table-column fixed="right" label="操作" width="270px">
        <template #default="scope">
          <ul>
            <li @click="editEquipModel(scope)">编辑</li>
            <li @click="objectModelFun(scope)">物模型</li>
            <li @click="schemeFun(scope)">方案</li>
            <li @click="copyFun(scope)">复制</li>
            <li @click="customerFun(scope)">下发客户</li>
            <li @click="delEquipModel(scope)">删除</li>
          </ul>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination :page-size="pageSize" :total="total" background hide-on-single-page
                   layout="prev, pager, next" @current-change="changePage"
                   @next-click="changePage" @prev-click="changePage"/>
    <!--新增或编辑设备型号弹窗-->
    <el-dialog
        v-model="equipModelAlert"
        :close-on-click-modal="false"
        :title="stateFlag?'新增设备型号':'编辑设备型号'"
        class="equipModelAlert"
        width="40%"
    >
      <el-form ref="equipmentFormRef" :model="equipModelForm" :rules="stateFlag?equipmentRulesCreate:equipmentRulesEdit"
               :validate="validate">
        <el-form-item label="PID" prop="code">
          <el-input v-model.trim="equipModelForm.code" placeholder="单行输入"/>
        </el-form-item>
        <el-form-item label="PID别名" prop="name">
          <el-input v-model="equipModelForm.name" placeholder="单行输入"/>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="equipModelForm.remark" placeholder="单行输入" type="textarea"/>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="equipModelFormSubmit">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!--物模型弹窗-->
    <el-dialog
        v-model="objectModelAlert"
        :close-on-click-modal="false"
        class="objectModelAlert"
        width="80%"
    >
      <div class="header">
        <p>设备型号管理</p>
        <div style="display: none;">
          <el-upload v-model:file-list="fileList" :before-upload="beforeUpload"
                     :data="personData" :show-file-list="false" accept=".xls,.xlsx"
                     action="https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15" class="upload-demo">
            <button id="fileName2" slot="trigger" class="selectFile" size="small">选择文件</button>
          </el-upload>
        </div>
        <div class="btn">
          <el-button @click="objectModelAlert=false">关闭</el-button>
          <el-button @click="createEquipModelFun">新建</el-button>
          <!--          <el-button @click="importModel">导入</el-button>-->
        </div>
      </div>
      <el-table v-loading="loadingModel" :data="objectModelData"
                :default-expand-all="true" border style="width: 100%;margin-top: 10px"
                table-layout="auto">
        <!--        <el-table-column label="序号" prop="" width="100px">-->
        <!--          <template #default="scope">-->
        <!--            {{ (pageIndexModel - 1) * pageSizeModel + scope.$index + 1 }}-->
        <!--          </template>-->
        <!--        </el-table-column>-->
        <el-table-column label="DP序号" prop="no"/>
        <el-table-column label="DP ID" prop="dpid"/>
        <el-table-column label="DP名称" prop="name"/>
        <el-table-column label="标识符" prop="code"/>
        <el-table-column label="数据传输类型" prop="trans_type">
          <template #default="scope">
            <p v-if="scope.row.trans_type==='rw'">可下发可上报</p>
            <p v-if="scope.row.trans_type==='ro'">只上报</p>
            <p v-if="scope.row.trans_type==='wr'">只下发</p>
          </template>
        </el-table-column>
        <el-table-column class-name="expansion" label="数据定义" prop="definition" type="expand" width="150px">
          <template #default="props">
            <div m="4">
              <ol>
                <li v-for="(item,index) in props.row.definition" :key="index">{{ item.content }}</li>
              </ol>
              <ol>
                <li v-for="(item,index) in props.row.definition" :key="index">{{ item.val }}</li>
              </ol>
            </div>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="150px">
          <template #default="scope">
            <ul>
              <li @click="editObjectModelFun(scope)">编辑</li>
              <li @click="delObjectModelFun(scope)">删除</li>
            </ul>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination :page-size="pageSizeModel" :total="totalModel" background hide-on-single-page
                     layout="prev, pager, next" @current-change="changePageModel"
                     @next-click="changePageModel" @prev-click="changePageModel"/>
    </el-dialog>
    <!--新增编辑dp点弹窗-->
    <el-dialog
        v-model="createObjectModelAlert"
        :close-on-click-modal="false"
        :title="stateModelFlag?'新增物模型':'编辑物模型'"
        class="createObjectModelAlert"
        width="40%"
    >
      <el-form :model="createObjectModelForm">
        <el-form-item label="DP序号" prop="no">
          <el-input v-model="createObjectModelForm.no" placeholder="单行输入" type="number"/>
        </el-form-item>
        <el-form-item label="DP_ID" prop="dpid">
          <el-input v-model="createObjectModelForm.dpid" placeholder="单行输入" type="number" @blur="dpidBlur($event)"/>
        </el-form-item>
        <el-form-item label="DP名称" prop="name">
          <el-input v-model="createObjectModelForm.name" placeholder="单行输入" show-word-limit/>
        </el-form-item>
        <el-form-item label="标识符" prop="code">
          <el-input v-model="createObjectModelForm.code" maxlength="25" placeholder="单行输入" show-word-limit
                    @blur="codeBlur($event)"/>
        </el-form-item>
        <el-form-item label="数据传输类型" prop="trans_type">
          <el-select v-model="createObjectModelForm.trans_type" placeholder="请选择数据传输类型">
            <el-option label="只上报" value="ro"/>
            <el-option label="只下发" value="wr"/>
            <el-option label="可下发可上报" value="rw"/>
          </el-select>
        </el-form-item>
        <el-form-item label="数据类型" prop="type">
          <el-select v-model="createObjectModelForm.type" placeholder="请选择数据类型">
            <el-option label="value,数值型" value="value"/>
            <el-option label="string,字符型" value="string"/>
            <el-option label="date,时间型" value="date"/>
            <el-option label="bool,布尔型" value="bool"/>
            <el-option label="enum,枚举型" value="enum"/>
            <el-option label="raw,透传型" value="raw"/>
            <el-option label="bitmap,故障型" value="bitmap"/>
            <el-option label="float,单精度浮点型" value="float"/>
            <el-option label="double,双精度浮点型" value="double"/>
          </el-select>
        </el-form-item>
        <el-form-item v-for="(item,index) in createObjectModelForm.definition"
                      :key="index" :label="index===0?'数据定义':''" prop="definition">
          <el-input v-model="item.content" maxlength="20" placeholder="数据定义名" show-word-limit style="width: 49%"/>
          <el-input v-model="item.val" onkeyup="this.value=this.value.replace(/\D/g,'')" placeholder="数据定义值"
                    style="width: 49%;transform: translateX(4%)" type="number">
            <template v-if="index===0" #suffix>
              <el-icon @click="addDefinition">
                <CirclePlus/>
              </el-icon>
            </template>
            <template v-if="index!==0" #suffix>
              <el-icon @click="removeDefinition(index)">
                <Remove/>
              </el-icon>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="createObjectModelFormSubmit">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!--删除物模型弹窗-->
    <el-dialog
        v-model="delObjectModelAlert"
        :close-on-click-modal="false"
        class="delObjectModelAlert"
        width="40%"
    >
      <h3>您确定要删除该物模型吗？</h3>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="delObjectModelAlert = false">取消</el-button>
        <el-button type="primary" @click="delObjectModelSubmit">确定</el-button>
      </span>
      </template>
    </el-dialog>
    <!--方案列表弹窗-->
    <el-dialog
        v-model="schemeAlert"
        :close-on-click-modal="false"
        class="schemeAlert"
        title="方案列表"
        width="60%"
    >
      <el-button @click="createScheme">新增方案</el-button>
      <el-table v-loading="loadingScheme" :data="schemeData" border style="width: 100%;margin-top: 10px"
                table-layout="auto">
        <el-table-column label="编号" prop="" width="100px">
          <template #default="scope">
            {{ (pageIndexScheme - 1) * pageSizeScheme + scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column label="方案名称" prop="name"/>
        <el-table-column label="数据标识" prop="code"/>
        <el-table-column label="包含数据项" prop="total"/>
        <el-table-column label="协议类型" prop="type"/>
        <el-table-column fixed="right" label="操作" width="150px">
          <template #default="scope">
            <ul>
              <li @click="lookSchemeFun(scope)">查看</li>
              <li @click="delSchemeFun(scope)">删除</li>
            </ul>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination :page-size="pageSizeScheme" :total="totalScheme" background hide-on-single-page
                     layout="prev, pager, next" @current-change="changePageScheme"
                     @next-click="changePageScheme" @prev-click="changePageScheme"/>
    </el-dialog>
    <!--方案列表的查看和新增弹窗-->
    <el-dialog
        v-model="createSchemeAlert"
        :close-on-click-modal="false"
        :title="isLook?'查看方案':'新增方案'"
        class="createSchemeAlert"
        width="80%"
    >
      <el-button @click="saveScheme">{{ isLook ? '编辑' : '保存' }}</el-button>
      <el-table :data="createSchemeData.first" border style="width: 100%;margin-top: 20px">
        <el-table-column label="方案名称">
          <template #default="scope">
            <el-input v-model="scope.row.name" :readonly="isLook"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="协议类型">
          <template #default="scope">
            <el-select v-model="scope.row.type" :disabled="isLook" placeholder="请选择协议类型">
              <el-option label="modbus" value="modbus"/>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="数据标识">
          <template #default="scope">
            <el-input v-model="scope.row.code" disabled="true" readonly="false"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="包含数据项">
          <template #default="scope">
            <el-input v-model="scope.row.total" :readonly="isLook" style="width: 70%;" type="number"></el-input>
            <el-button v-show="!isLook" class="generate" @click="generate">生成</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-table v-show="idShowAddress" :data="createSchemeData.first" border style="width: 25%;margin-left: 50%">
        <el-table-column label="起始地址">
          <template #default="scope">
            <el-input v-model="scope.row.start_addr" :readonly="isLook" type="number"
                      @input="generatCodeChange"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="个数">
          <template #default="scope">
            <el-input v-model="scope.row.num" :readonly="isLook" type="number" @input="generatCodeChange"></el-input>
          </template>
        </el-table-column>
      </el-table>
      <el-table :data="createSchemeData.third" border style="width: 100%;margin-top: 30px">
        <el-table-column label="数据项">
          <template #default="scope">
            <el-input v-model="scope.row.name" :readonly="isLook"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="数据标识">
          <template #default="scope">
            <el-input v-model="scope.row.code" disabled="true" readonly="false"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="位置">
          <template #default="scope">
            <el-input v-model="scope.row.addr" :readonly="isLook" type="number"
                      @input="generatItemCodeChange(scope)"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="数据类型">
          <template #default="scope">
            <el-select v-model="scope.row.type" :disabled="isLook" placeholder="请选择数据类型"
                       @change="generatItemCodeChange(scope)">
              <el-option v-for="(item,index) in dataType" :key="index" :label="item" :value="item"/>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="DP序号">
          <template #default="scope">
            <el-input v-model="scope.row.dpid" :readonly="isLook" type="number"
                      @input="generatItemCodeChange(scope)"></el-input>
          </template>
        </el-table-column>
        <el-table-column v-if="idShowAddress" label="DP类型">
          <template #default="scope">
            <el-select v-model="scope.row.dp_type" :disabled="isLook" placeholder="请选择DP类型"
                       @change="generatItemCodeChange(scope)">
              <el-option v-for="(item,index) in dpType" :key="index" :label="item" :value="item"/>
            </el-select>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <!--删除方案-->
    <el-dialog v-model="delSchemeAlert" :close-on-click-modal="false" class="delSchemeAlert" width="40%">
      <h3>您确定要删除该方案吗？</h3>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="delSchemeAlert = false">取消</el-button>
        <el-button type="primary" @click="delSchemeSubmit">确定</el-button>
      </span>
      </template>
    </el-dialog>
    <!--下发客户弹窗-->
    <el-dialog
        v-model="customerAlert"
        :close-on-click-modal="false"
        class="customerAlert"
        title="下发客户"
        width="40%"
    >
      <el-form ref="equipmentFormRef" :model="customerForm" :rules="equipmentRulesCreate">
        <el-form-item label="PID" prop="code">
          <el-input v-model="customerForm.code" placeholder="单行输入"/>
        </el-form-item>
        <el-form-item label="PID别名" prop="name">
          <el-input v-model="customerForm.name" placeholder="单行输入"/>
        </el-form-item>
        <el-form-item label="选择客户" prop="b_id">
          <el-select v-model="customerForm.b_id" filterable placeholder="请选择客户">
            <el-option v-for="item in customerList"
                       :key="item.name"
                       :label="item.name"
                       :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="customerFormSubmit">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!--复制弹窗-->
    <el-dialog
        v-model="copyAlert"
        :close-on-click-modal="false"
        class="copyAlert"
        title="复制"
        width="40%"
    >
      <el-form ref="equipmentFormRef" :model="copyForm" :rules="equipmentRulesCreate">
        <el-form-item label="PID" prop="code">
          <el-input v-model="copyForm.code" placeholder="单行输入"/>
        </el-form-item>
        <el-form-item label="PID别名" prop="name">
          <el-input v-model="copyForm.name" placeholder="单行输入"/>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="copyFormSubmit">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!--删除设备型号弹窗-->
    <el-dialog v-model="delEquipModelAlert" :close-on-click-modal="false" class="delEquipModelAlert" width="40%">
      <h3>您确定要删除该设备型号吗？</h3>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="delEquipModelAlert = false">取消</el-button>
        <el-button type="primary" @click="delEquipModelFun">确定</el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {onMounted, reactive, ref, watch} from "vue"
import './monitoring.less'
import {ElMessage} from "element-plus";
import {CirclePlus, Remove} from '@element-plus/icons-vue'
import {v1 as uuidv1} from 'uuid'

import {
  codeQuery, copyModel, createGeneratCode,
  dpidQuery,
  equipmentQuery, generatItemCode,
  getEquipmentList, getMaxNo,
  getModelList,
  getPageEquipmentList, getPageModelDefinList, getPageModelList, getPageSchemeList, getSchemeList, lookSchemeItem,
  saveEquipmentList, saveModelList, savePageModelDefinList, savePageSchemeList, saveSchemeDataList, sendToB
} from "@/api/modules/monitoring";
import {userObj} from "@/assets/js/userObj";
import {getPageCustomerList, importGateway, uoloadFile} from "@/api/modules/customer";


// 获取列表******************************************************
// 网关总条数
const getEquipmentListFun = function () {
  getEquipmentList({
    b_id: 0
  }).then(res => {
    total.value = res.data
    getPageEquipmentListFun(pageIndex.value)
  }).catch(err => {
    console.log(err);
  })
}
// 每页列表
const getPageEquipmentListFun = function (num, value) {
  let obj = {
    pageSize: pageSize.value,
    pageIndex: num,
    b_id: 0
  }
  if (value) {
    obj.name = value
  }
  loading.value = true
  getPageEquipmentList(obj).then(res => {
    equipModelData.value = res.data
    loading.value = false
  }).catch(err => {
    console.log(err);
  })
}

// 当前展示页数
let pageIndex = ref(1)
// 每页数量
let pageSize = ref(20)
// 管理员总条数
let total = ref()
onMounted(async () => {
  getEquipmentListFun()
})

let loading = ref(true)
// 设备型号表格--------------------------------------------------------
let equipModelData = ref([])
// 点击切换页数
const changePage = function (value) {
  pageIndex.value = value
  getPageEquipmentListFun(value)
}


// 监测设备搜索数据--------------------------------------------------------
let searchData = ref()
// 点击搜索
const searchBtn = function () {
  getPageEquipmentListFun(pageIndex.value, searchData.value)
}
document.onkeydown = function (e) { // 回车提交表单
// 兼容FF和IE和Opera
  var theEvent = window.event || e;
  var code = theEvent.keyCode || theEvent.which || theEvent.charCode;
  if (code == 13) {
    searchBtn();
  }
}


// 新建编辑设备型号型号的相关事件与数据******************************************************
// 新建或编辑设备型号弹窗
let equipModelAlert = ref(false)
// 新建或编辑设备型号的弹窗表单
let equipModelForm = ref({
  code: '',
  name: '',
  remark: '',
  src: 1,
  pre_id: 1,
  b_id: 0,
  uuid: uuidv1(),
  create_time: Date.now(),
  manager_id: Number(userObj()),
  status: 1,
  version: 1
})

// 判断是新建还是编辑
let stateFlag = ref()
// 新建设备型号
const createEquipModel = function () {
  // 每次新建把表单清空
  equipModelForm.value = {
    code: '',
    name: '',
    remark: '',
    src: 1,
    pre_id: 1,
    b_id: 0,
    uuid: uuidv1(),
    create_time: Date.now(),
    manager_id: Number(userObj()),
    status: 1,
    version: 1
  }
  stateFlag.value = true
  equipModelAlert.value = true
}
// 编辑设备型号
let editId = ref()
let editCode = ref()
const editEquipModel = function (scope) {
  equipModelForm.value.code = scope.row.code
  equipModelForm.value.name = scope.row.name
  equipModelForm.value.remark = scope.row.remark
  equipModelForm.value.uuid = scope.row.uuid
  // 获取本行信息
  editId.value = scope.row.id
  editCode.value = scope.row.code
  stateFlag.value = false
  equipModelAlert.value = true
}


// 网关PID规则
let pid1 = async (rule, value, callback) => {
  let repeat = false
  await equipmentQuery({code: value}).then(res => {
    if (res.data.id) {
      repeat = true
    } else {
      repeat = false
    }
  }).catch(err => {
    console.log(err);
  })
  if (repeat === true) {
    callback(new Error('PID重复!'))
    equipModelForm.value.code = ''
    customerForm.value.code = ''
    copyForm.value.code = ''
  } else {
    callback()
  }
}
let pid2 = async (rule, value, callback) => {
  let repeat = false
  await equipmentQuery({code: value}).then(res => {
    if (res.data.id) {
      repeat = true
    } else {
      repeat = false
    }
  }).catch(err => {
    console.log(err);
  })
  if (repeat === true && editCode.value !== value) {
    callback(new Error('PID重复!'))
    equipModelForm.value.code = ''
    customerForm.value.code = ''
    copyForm.value.code = ''
  } else {
    callback()
  }
}
// 编辑新建网关的表单规则
const equipmentRulesCreate = reactive({
  // code: [{trigger: "blur", validator: pid1}],
})
const equipmentRulesEdit = reactive({
  // code: [{trigger: "blur", validator: pid2}],
})

// 表单元素
let equipmentFormRef = ref()
// 新建或编辑设备型号提交
const equipModelFormSubmit = function () {
  if (equipmentFormRef) {
    equipmentFormRef.value.validate((valid) => {
      //提交成功
      if (valid) {
        if (equipModelForm.value.code !== '' && equipModelForm.value.name !== '') {
          let obj = equipModelForm.value
          let uuidArr = []
          for (let i = 0; i < obj.uuid.length; i++) {
            if (obj.uuid[i] !== '-') {
              uuidArr.push(obj.uuid[i])
            }
          }
          uuidArr = uuidArr.join('')
          obj.uuid = uuidArr
          obj.code = obj.code.replace(/\s*/g, "")
          obj.name = obj.name.replace(/\s*/g, "")
          if (!stateFlag.value) {
            obj.id = editId.value
          }
          saveEquipmentList(obj).then(res => {
            if (res.data.id) {
              getEquipmentListFun()
              equipModelAlert.value = false
            } else {
              ElMessage({
                message: 'PID已存在，请重新输入',
                type: 'info'
              })
            }
          }).catch(err => {
            console.log(err);
          })
        } else {
          ElMessage({
            message: '缺少必填项',
            type: 'info'
          })
        }
      }
    })
  }
}


// 物模型的相关事件和数据******************************************************
// 获取物模型列表总数
const getModelListFun = function () {
  getModelList({
    model_id: model_id.value
  }).then(res => {
    totalModel.value = res.data
    getPageModelListFun(pageIndexModel.value)
  }).catch(err => {
    console.log(err);
  })
}
// 获取物模型某页列表
let timer
const getPageModelListFun = function (num) {
  loadingModel.value = true
  if (timer !== null) {
    clearTimeout(timer);
  }
  timer = setTimeout(() => {
    getPageModelList({
      pageSize: pageSizeModel.value,
      pageIndex: num,
      model_id: model_id.value
    }).then(res => {
      objectModelData.value = res.data
      loadingModel.value = false
      setTimeout(() => {
        for (let i = 0; i < document.querySelectorAll('.objectModelAlert tr').length; i++) {
          document.querySelectorAll('.objectModelAlert tr')[i].style.display = 'table-row'
        }
      }, 100)
      for (let i = 0; i < res.data.length; i++) {
        getPageModelDefinList({
          pageSize: -1,
          pageIndex: 1,
          model_dp_id: res.data[i].id
        }).then(res => {
          let obj = objectModelData.value
          obj[i].definition = res.data
          objectModelData.value = obj
          if (res.data.length > 1) {
            setTimeout(() => {
              let expansion = document.querySelectorAll('.expansion')
              let num = expansion[i + 1].childNodes[0].childNodes.length
              if (num === 1) {
                let p = document.createElement("p")
                p.innerHTML = '多个'
                let a = expansion[i + 1].childNodes[0].childNodes[0]
                expansion[i + 1].childNodes[0].insertBefore(p, a)
                expansion[i + 1].childNodes[0].style.width = '80px'
                expansion[i + 1].childNodes[0].style.display = 'flex'
                expansion[i + 1].childNodes[0].style.justifyContent = 'center'
              } else {
                expansion[i + 1].childNodes[0].childNodes[0].innerHTML = '多个'
                document.querySelectorAll('.el-table__expand-icon')[i].style.display = 'block'
                expansion[i + 1].childNodes[0].style.width = '80px'
                expansion[i + 1].childNodes[0].style.display = 'flex'
                expansion[i + 1].childNodes[0].style.justifyContent = 'center'
              }
            }, 200)
          } else {
            setTimeout(() => {
              let expansion = document.querySelectorAll('.expansion')
              expansion[i + 1].parentNode.nextSibling.style.display = 'none'
              let num = expansion[i + 1].childNodes[0].childNodes.length
              document.querySelectorAll('.el-table__expand-icon')[i].style.display = 'none'
              if (res.data.length !== 0) {
                if (num === 1) {
                  let p = document.createElement("p")
                  p.innerHTML = res.data[0].content + ':' + res.data[0].val
                  let a = expansion[i + 1].childNodes[0].childNodes[0]
                  expansion[i + 1].childNodes[0].insertBefore(p, a)
                } else {
                  expansion[i + 1].childNodes[0].childNodes[0].innerHTML = res.data[0].content + ':' + res.data[0].val
                }
              } else {
                if (num === 1) {
                  let p = document.createElement("p")
                  p.innerHTML = ''
                  let a = expansion[i + 1].childNodes[0].childNodes[0]
                  expansion[i + 1].childNodes[0].insertBefore(p, a)
                } else {
                  expansion[i + 1].childNodes[0].childNodes[0].innerHTML = ''
                }
              }
            }, 200)
          }
        }).catch(err => {
          console.log(err);
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }, 500)
}
// 当前展示页数
let pageIndexModel = ref(1)
// 每页数量
let pageSizeModel = ref(20)
// 管理员总条数
let totalModel = ref()
let loadingModel = ref(false)

// 物模型弹窗是否展示-----------------------------
let objectModelAlert = ref(false)
// 物模型弹窗数据
let objectModelData = ref([])
// 所属型号model_id
let model_id = ref()
let code = ref()
// 点击打开物模型弹窗
const objectModelFun = function (scope) {
  model_id.value = scope.row.id
  code.value = scope.row.code
  objectModelAlert.value = true
  getModelListFun()
}
// 点击切换页数
const changePageModel = function (value) {
  pageIndexModel.value = value
  getPageModelListFun(value)
}


// 物模型新建弹窗是否展示---------------------------
let createObjectModelAlert = ref(false)
// 物模型弹窗数据
let createObjectModelForm = ref({
  no: 1,
  dpid: '',
  name: '',
  code: '',
  trans_type: '',
  type: '',
  model_id: model_id.value,
  create_time: Date.now(),
  manager_id: Number(userObj()),
  status: 1,
  version: 1,
  definition: [{content: '', val: ''}],
})
let definitionArr = ref([])
// 判断是新建还是编辑
let stateModelFlag = ref()
// 新建物模型
const createEquipModelFun = function () {
  createObjectModelForm.value = {
    no: 1,
    dpid: '',
    name: '',
    code: '',
    trans_type: '',
    type: '',
    model_id: model_id.value,
    create_time: Date.now(),
    manager_id: Number(userObj()),
    status: 1,
    version: 1,
    definition: [{content: '', val: ''}],
  }
  // 获取新建的DP序号
  getMaxNo({
    model_id: model_id.value
  }).then(res => {
    if (res.data) {
      createObjectModelForm.value.no = Number(res.data.no) + 1
    }
  }).catch(err => {
    console.log(err);
  })

  createObjectModelAlert.value = true
  stateModelFlag.value = true
  editModelCode.value = ''
  editModelDpid.value = ''
}
// 编辑的id
let editModelId = ref()
let editModelCode = ref()
let editModelDpid = ref()
let oldDefin = ref()
// 编辑物模型
const editObjectModelFun = function (scope) {
  createObjectModelForm.value.dpid = scope.row.dpid
  createObjectModelForm.value.name = scope.row.name
  createObjectModelForm.value.code = scope.row.code
  createObjectModelForm.value.trans_type = scope.row.trans_type
  createObjectModelForm.value.type = scope.row.type
  createObjectModelForm.value.no = scope.row.no

  getPageModelDefinList({
    pageSize: -1,
    pageIndex: 1,
    model_dp_id: scope.row.id
  }).then(res => {
    if (res.data.length > 0) {
      createObjectModelForm.value.definition = res.data
      oldDefin.value = res.data
    } else {
      createObjectModelForm.value.definition = [{content: '', val: ''}]
      oldDefin.value = [{content: ''}]
    }
  }).catch(err => {
    console.log(err);
  })


  editModelId.value = scope.row.id
  editModelCode.value = scope.row.code
  editModelDpid.value = scope.row.dpid
  createObjectModelAlert.value = true
  stateModelFlag.value = false
  dpidFlag.value = '1'
  codeFlag.value = '1'
}
// 添加数据定义
const addDefinition = function () {
  let arr = createObjectModelForm.value
  if (arr.definition.length !== 16) {
    arr.definition.push({
      contnet: '',
      val: ''
    })
    createObjectModelForm.value = arr
  } else {
    ElMessage({
      message: '长度达到限制',
      type: 'info'
    })
  }
}
// 删除数据定义
const removeDefinition = function (index) {
  let arr = createObjectModelForm.value
  arr.definition.splice(index, 1)
  createObjectModelForm.value = arr
}
// dpid输入框失去焦点事件
let dpidFlag = ref('1')
const dpidBlur = function (event) {
  dpidQuery({
    model_id: model_id.value,
    dpid: event.target.value
  }).then(res => {
    if (res.data.id && res.data.dpid !== editModelDpid.value) {
      dpidFlag.value = '0'
      ElMessage({
        message: '本设备的dpid重复',
        type: 'info',
      })
      createObjectModelForm.value.dpid = ''
    } else {
      dpidFlag.value = '1'
    }
  }).catch(err => {
    console.log(err);
  })
}
// code输入框失去焦点事件
let codeFlag = ref('1')
const codeBlur = function (event) {
  codeQuery({
    model_id: model_id.value,
    code: event.target.value
  }).then(res => {
    if (res.data.id && res.data.code !== editModelCode.value) {
      codeFlag.value = '0'
      ElMessage({
        message: '本设备的标识符重复',
        type: 'info',
      })
      createObjectModelForm.value.code = ''
    } else {
      codeFlag.value = '1'
    }
  }).catch(err => {
    console.log(err);
  })
}
// 物模型新建提交
const createObjectModelFormSubmit = function () {
  if (dpidFlag.value === '1' && codeFlag.value === '1') {
    if (createObjectModelForm.value.dpid !== '' && createObjectModelForm.value.name !== '' && createObjectModelForm.value.code !== '' && createObjectModelForm.value.trans_type !== '' && createObjectModelForm.value.type !== '') {
      let obj = createObjectModelForm.value
      obj.dpid = obj.dpid.replace(/\s*/g, "")
      obj.name = obj.name.replace(/\s*/g, "")
      obj.code = obj.code.replace(/\s*/g, "")
      obj.model_id = model_id.value
      if (!stateModelFlag.value) {
        obj.id = editModelId.value
      }
      saveModelList(obj).then(async res => {
        if (res.data.id) {
          getModelListFun()
          createObjectModelAlert.value = false
          let model_dp_id = res.data.id

          // 如果物模型为编辑,那就先删除该物模型的数据定义
          if (!stateModelFlag.value) {
            let id = res.data.id
            // 先获取该物模型的所有数据定义
            await getPageModelDefinList({
              model_dp_id: res.data.id,
              pageIndex: 1,
              pageSize: -1
            }).then(async res => {
              // 然后删除所有的数据定义
              for (let i = 0; i < res.data.length; i++) {
                await savePageModelDefinList({
                  id: res.data[i].id,
                  model_dp_id: model_dp_id,
                  status: 0,
                }).then(res => {
                  if (res.data.id) {
                    getModelListFun()
                  }
                }).catch(err => {
                  console.log(err);
                })
              }

            }).catch(err => {
              console.log(err);
            })
          }
          for (let i = 0; i < createObjectModelForm.value.definition.length; i++) {
            createObjectModelForm.value.definition[i].content = createObjectModelForm.value.definition[i].content.replace(/\s*/g, "")
            createObjectModelForm.value.definition[i].val = String(createObjectModelForm.value.definition[i].val).replace(/\s*/g, "")
            // 新增编辑数据定义
            savePageModelDefinList({
              model_dp_id: res.data.id,
              content: createObjectModelForm.value.definition[i].content,
              val: createObjectModelForm.value.definition[i].val,
              create_time: Date.now(),
              status: 1
            }).then(res => {
              let arr = objectModelData.value
              for (let j = 0; j < arr.length; j++) {
                if (arr[j].id === res.data.id) {
                  arr[j].definition.push(res.data)
                }
              }
              objectModelData.value = arr
              getModelListFun()
            }).catch(err => {
              console.log(err);
            })
          }
        }
      }).catch(err => {
        console.log(err);
      })
    } else {
      ElMessage({
        message: '缺少必填项',
        type: 'info'
      })
    }
  } else if (dpidFlag.value === '0' && codeFlag.value === '1') {
    ElMessage({
      message: '本设备的dpid重复',
      type: 'info'
    })
    createObjectModelForm.value.dpid = ''
  } else if (dpidFlag.value === '1' && codeFlag.value === '0') {
    ElMessage({
      message: '本设备的标识符重复',
      type: 'info',
    })
    createObjectModelForm.value.code = ''
  } else if (dpidFlag.value === '0' && codeFlag.value === '0') {
    ElMessage({
      message: '本设备的dpid和标识符重复',
      type: 'info',
    })
    createObjectModelForm.value.dpid = ''
    createObjectModelForm.value.code = ''
  }
}


// 物模型导入-------------------------------
const importModel = function () {
  document.getElementById("fileName2").click();
}
// 上传
const beforeUpload = function (file) {
  let fd = new FormData();
  fd.append('file', file); //传文件
  fd.append('path', '/mnt/xvdb/html/gw/storage/upload/' + new Date().getFullYear() + new Date().getMonth() + 1); //传其他参数
  var name = uuidv1()
  var index = file.name.lastIndexOf(".");
  var type = file.name.substring(index, file.name.length);
  fd.append('fileName', name + type); //传其他参数
  uoloadFile(fd).then(res => {
    if (res.data) {
      importSubprojectFun(res.data);
    }
  }).catch(err => {
    console.log(err)
  })
}
// 导入
const importSubprojectFun = function (file) {
  // let fd = new FormData();
  // fd.append('file', file); //传文件
  // fd.append('manager_id', manager_id.value); //传其他参数
  // fd.append('b_id', b_id.value); //传其他参数
  // importGateway(fd).then(res => {
  //   if (res.data.res) {
  //     ElMessage({
  //       message: '导入成功',
  //       type: 'success'
  //     })
  // getModelListFun()
  //   }
  // }).catch(err => {
  //   console.log(err);
  // })
}


// 物模型删除弹窗是否展示---------------------------
let delObjectModelAlert = ref(false)
let delModelId = ref()
let delModelDpid = ref()
let delModelCode = ref()
// 点击打开删除物模型弹窗
const delObjectModelFun = function (scope) {
  delModelId.value = scope.row.id
  delModelDpid.value = scope.row.dpid + '_' + scope.row.id
  delModelCode.value = scope.row.code + '_' + scope.row.id


  delObjectModelAlert.value = true
}
// 确定删除物模型
const delObjectModelSubmit = function () {
  saveModelList({
    id: delModelId.value,
    dpid: delModelDpid.value,
    code: delModelCode.value,
    status: 0,
  }).then(res => {
    if (res.data.id) {
      getModelListFun()
    }
  }).catch(err => {
    console.log(err);
  })


  delObjectModelAlert.value = false
  ElMessage({
    message: '删除成功',
    type: 'success',
  })
}


// 方案的相关事件和数据******************************************************
// 获取数据类型
let dataType = ref([
  'U16_A',
  'U16_AB',
  'U16_BA',
  'S16_AB',
  'S16_BA',
  'U32_ABCD',
  'U32_CDAB',
  'U32_BADC',
  'U32_DCBA',
  'S32_ABCD',
  'S32_CDAB',
  'S32_BADC',
  'S32_DCBA',
  'F32_ABCD',
  'F32_CDAB',
  'F32_BADC',
  'F32_DCBA',
  'U64_ABCDEFGH',
  'U64_GHEFCDAB',
  'U64_BADCFEHG',
  'U64_HGFEDCBA',
  'S64_ABCDEFGH',
  'S64_GHEFCDAB',
  'S64_BADCFEHG',
  'S64_HGFEDCBA',
  'F64_ABCDEFGH',
  'F64_GHEFCDAB',
  'F64_BADCFEHG',
  'F64_HGFEDCBA'
])
// 获取dp类型
let dpType = ref([
  'raw',
  'bool',
  'value',
  'string',
  'enum',
  'bitmap',
  'float',
  'double',
  'value_1',
  'value_2',
  'value_3',
  'value_4',
  'value_ - 1',
  'value_ - 2',
  'value_ - 3',
  'value_ - 4',
  'float_1',
  'float_2',
  'float_3',
  'float_4',
  'float_ - 1',
  'float_ - 2',
  'float_ - 3',
  'float_ - 4',
  'double_1',
  'double_2',
  'double_3',
  'double_4',
  'double_ - 1',
  'double_ - 2',
  'double_ - 3',
  'double_ - 4',
])


// 当前展示页数
let pageIndexScheme = ref(1)
// 每页数量
let pageSizeScheme = ref(20)
// 管理员总条数
let totalScheme = ref()
let loadingScheme = ref(true)
// 获取方案总条数
let getSchemeListFun = function () {
  getSchemeList({
    model_id: model_id.value
  }).then(res => {
    totalScheme.value = res.data
    getPageSchemeListFun(pageIndexScheme.value)
  }).catch(err => {
    console.log(err);
  })
}
// 获取方案某个列表
let getPageSchemeListFun = function (num) {
  loadingScheme.value = true
  getPageSchemeList({
    pageIndex: num,
    pageSize: pageSizeScheme.value,
    model_id: model_id.value
  }).then(res => {
    schemeData.value = res.data
    loadingScheme.value = false
  }).catch(err => {
    console.log(err);
  })
}
// 方案列表弹窗是否展示------------------------------------
let schemeAlert = ref(false)
// 方案弹窗数据
let schemeData = ref([])
// 点击打开方案弹窗
const schemeFun = function (scope) {
  schemeAlert.value = true
  model_id.value = scope.row.id
  getSchemeListFun()
}
// 点击切换页数
const changePageScheme = function (value) {
  pageIndexScheme.value = value
  getPageSchemeListFun(value)
}

// 生成数据标识
let timer2 = null
const generatCodeChange = function () {
  if (timer2 !== null) {
    clearTimeout(timer2);
  }
  timer2 = setTimeout(() => {
    if (createSchemeData.value.first[0].start_addr && createSchemeData.value.first[0].num) {
      createGeneratCode({
        start_addr: createSchemeData.value.first[0].start_addr,
        total: createSchemeData.value.first[0].num
      }).then(res => {
        if (res.data.res === '1') {
          createSchemeData.value.first[0].code = res.data.msg
        }
      }).catch(err => {
        console.log(err);
      })
    }
  }, 200)
}
// 生成数据项数据标识
let timer3 = null
const generatItemCodeChange = function (scope) {
  if (timer3 !== null) {
    clearTimeout(timer3);
  }
  timer3 = setTimeout(() => {
    if (createSchemeData.value.third[scope.$index].addr && createSchemeData.value.third[scope.$index].type && createSchemeData.value.third[scope.$index].dpid && createSchemeData.value.third[scope.$index].dp_type) {
      generatItemCode({
        addr: createSchemeData.value.third[scope.$index].addr,
        type: createSchemeData.value.third[scope.$index].type,
        dpid: createSchemeData.value.third[scope.$index].dpid,
        dp_type: createSchemeData.value.third[scope.$index].dp_type,
      }).then(res => {
        if (res.data.res === '1') {
          createSchemeData.value.third[scope.$index].code = res.data.msg
        }
      }).catch(err => {
        console.log(err);
      })
    }
  }, 200)
}


// 新增或查看方案弹窗是否显示----------------------------
let createSchemeAlert = ref(false)
// 新建还是编辑状态
let schemeStateFlag = ref()
// 判断是查看状态还是编辑状态
let isLook = ref(false)
// 监听查看状态还是编辑状态
watch(isLook, (newValue) => {
  setTimeout(() => {
    let item = document.querySelectorAll('.createSchemeAlert .el-input .el-input__wrapper')
    if (newValue) {
      for (let i = 0; i < item.length; i++) {
        item[i].style.boxShadow = "0 0 0 0"
      }
    } else {
      for (let i = 0; i < item.length; i++) {
        item[i].style.boxShadow = "0 0 0 1px var(--el-input-border-color,var(--el-border-color)) inset"
      }
    }
  }, 200)
}, {immediate: true})
// 新增方案数据
let createSchemeData = ref({
  first: [
    {
      name: '',
      code: '',
      total: 1,
      type: '',
      start_addr: '',
      num: '',
      create_time: Date.now(),
      model_id: model_id.value,
      manager_id: Number(userObj()),
      status: 1,
      version: 1
    }
  ],
  third: [
    {
      name: '',
      code: '',
      addr: '',
      type: '',
      dpid: '',
      dp_type: '',
      create_time: Date.now(),
      manager_id: Number(userObj()),
      status: 1,
      version: 1,
    }
  ]
})
// 点击查看方案
let editSchemeId = ref()
const lookSchemeFun = function (scope) {
  createSchemeAlert.value = true
  isLook.value = true
  editSchemeId.value = scope.row.id
  createSchemeData.value.first[0] = scope.row
  lookSchemeItem({
    model_plan_id: scope.row.id,
    pageSize: -1,
    pageIndex: 1
  }).then(res => {
    createSchemeData.value.third = res.data
  }).catch(err => {
    console.log(err);
  })
}
// 点击新增方案
const createScheme = function () {
  createSchemeAlert.value = true
  isLook.value = false
  schemeStateFlag.value = true
  createSchemeData.value = {
    first: [
      {
        name: '',
        code: '',
        total: 1,
        type: '',
        start_addr: '',
        num: '',
        create_time: Date.now(),
        model_id: model_id.value,
        manager_id: Number(userObj()),
        status: 1,
        version: 1
      }
    ],
    third: [
      {
        name: '',
        code: '',
        addr: '',
        type: '',
        dpid: '',
        dp_type: '',
        create_time: Date.now(),
        manager_id: Number(userObj()),
        status: 1,
        version: 1,
      }
    ]
  }
}
// 点击生成数据项
const generate = function () {
  let num = Number(createSchemeData.value.first[0].total)
  if (num - createSchemeData.value.third.length >= 0) {
    let createSchemeDataArr = createSchemeData.value.third
    for (let i = 0; i < num - createSchemeData.value.third.length; i++) {
      setTimeout(() => {
        createSchemeDataArr.push({
          name: '',
          code: '',
          addr: '',
          type: '',
          dpid: '',
          dp_type: '',
          create_time: Date.now(),
          manager_id: Number(userObj()),
          status: 1,
          version: 1,
        })
      }, 10)
    }
    createSchemeData.value.third = createSchemeDataArr
  } else {
    let number = createSchemeData.value.third.length - num
    createSchemeData.value.third.splice(num, number)
  }
}
// 判断起始地址和个数是否显示
let idShowAddress = ref(false)
// 监听类型是否为modbus
watch(() => createSchemeData.value.first[0].type, (newValue, oldValue) => {
  if (newValue === 'modbus') {
    idShowAddress.value = true
  } else {
    idShowAddress.value = false
  }
}, {immediate: true, deep: true})


// 新增编辑方案提交
const saveScheme = function () {
  // 判断现在是否是可编辑状态，true是不可编辑
  if (isLook.value) {
    // 点击编辑
    isLook.value = false
    schemeStateFlag.value = false
  } else {
    // 点击提交
    // 判断是新增提交还是编辑提交,true是新增
    if (!schemeStateFlag.value) {
      createSchemeData.value.first[0].id = editSchemeId.value
    }
    // 先提交方案列表
    createSchemeData.value.first[0].model_id = model_id.value
    if (createSchemeData.value.first[0].name !== '' && createSchemeData.value.first[0].code !== '' && createSchemeData.value.first[0].total !== '' && createSchemeData.value.first[0].type !== '' && createSchemeData.value.first[0].start_addr !== '' && createSchemeData.value.first[0].num !== '') {
      savePageSchemeList(createSchemeData.value.first[0]).then(async res => {
        if (res.data.id) {
          isLook.value = true
          createSchemeAlert.value = false
          getSchemeListFun()
          // 再提交方案数据
          // 判断如果是编辑就先删除方案数据
          if (!schemeStateFlag.value) {
            await lookSchemeItem({
              model_plan_id: res.data.id,
              pageSize: -1,
              pageIndex: 1
            }).then(async res => {
              for (let i = 0; i < res.data.length; i++) {
                await saveSchemeDataList({
                  id: res.data[i].id,
                  status: 0
                }).then(res => {
                  // console.log(res);
                }).catch(err => {
                  console.log(err);
                })
              }
            }).catch(err => {
              console.log(err);
            })
          }
          // 然后进行提交
          for (let i = 0; i < createSchemeData.value.third.length; i++) {
            saveSchemeDataList({model_plan_id: res.data.id, ...createSchemeData.value.third[i]}).then(res => {
              if (res.data.id) {
                isLook.value = true
                createSchemeAlert.value = false
              }
            }).catch(err => {
              console.log(err);
            })
          }
        }
      }).catch(err => {
        console.log(err);
      })
    } else {
      ElMessage({
        message: '缺少必填项',
        type: 'info'
      })
    }
  }
}


// 删除方案弹窗是否显示----------------------------------
let delSchemeAlert = ref(false)
// 点击删除方案
let delSchemeId = ref()
const delSchemeFun = function (scope) {
  delSchemeId.value = scope.row.id
  delSchemeAlert.value = true
}
// 确定删除方案
const delSchemeSubmit = function () {
  savePageSchemeList({
    id: delSchemeId.value,
    status: 0
  }).then(res => {
    if (res.data.id) {
      getSchemeListFun()
      delSchemeAlert.value = false
      ElMessage({
        message: '删除成功',
        type: 'success',
      })
    }
  }).catch(err => {
    console.log(err);
  })
}


// 复制的相关事件和数据******************************************************
// 复制弹窗是否展示
let copyAlert = ref(false)
// 复制弹窗数据
let copyForm = ref({
  code: '',
  name: '',
  model_id: '',
  manager_id: Number(userObj())
})
// 点击打开复制弹窗
const copyFun = function (scope) {
  copyAlert.value = true
  copyForm.value = {
    code: '',
    name: '',
    model_id: scope.row.id,
    manager_id: Number(userObj())
  }
}
// 点击提交复制表单
const copyFormSubmit = function () {
  if (equipmentFormRef) {
    equipmentFormRef.value.validate((valid) => {
      //提交成功
      if (valid) {
        if (copyForm.value.code !== '' && copyForm.value.name !== '') {
          copyModel(copyForm.value).then(res => {
            if (res.data.res === '1') {
              copyAlert.value = false
              ElMessage({
                message: '复制成功',
                type: 'success'
              })
              getEquipmentListFun()
            }
          }).catch(err => {
            console.log(err);
          })
        } else {
          ElMessage({
            message: '缺少必填项',
            type: 'info'
          })
        }
      }
    })
  }
}


// 下发客户的相关事件和数据******************************************************
// 客户列表
let customerList = ref()
// 下发客户弹窗是否展示
let customerAlert = ref(false)
// 下发客户弹窗数据
let customerForm = ref({
  code: '',
  name: '',
  b_id: '',
  model_id: '',
  manager_id: Number(userObj())
})
// 点击打开下发客户弹窗
const customerFun = function (scope) {
  customerAlert.value = true
  customerForm.value = {
    code: scope.row.code,
    name: scope.row.name,
    b_id: '',
    model_id: scope.row.id,
    manager_id: Number(userObj())
  }
  // 获取客户列表
  getPageCustomerList({
    pageSize: -1,
    pageIndex: 1
  }).then(res => {
    customerList.value = res.data
  }).catch(err => {
    console.log(err);
  })
}
// 点击提交下发客户
const customerFormSubmit = function () {
  if (equipmentFormRef) {
    equipmentFormRef.value.validate((valid) => {
      //提交成功
      if (valid) {
        if (customerForm.value.code !== '' && customerForm.value.name !== '') {
          sendToB(customerForm.value).then(res => {
            if (res.data.res === '1') {
              customerAlert.value = false
              ElMessage({
                message: '下发成功',
                type: 'success'
              })
            }
          }).catch(err => {
            console.log(err);
          })
        } else {
          ElMessage({
            message: '缺少必填项',
            type: 'info'
          })
        }
      }
    })
  }
}


// 删除设备型号的相关事件与数据******************************************************
// 是否展示删除设备型号弹窗
let delEquipModelAlert = ref(false)
// 编辑的网关id
let delId = ref()
let delCode = ref()
// 删除设备型号
const delEquipModel = function (scope) {
  delId.value = scope.row.id
  delCode.value = scope.row.code + '_' + scope.row.id
  delEquipModelAlert.value = true
}
// 确定删除设备型号
const delEquipModelFun = function () {
  saveEquipmentList({
    id: delId.value,
    code: delCode.value,
    status: 0
  }).then(res => {
    if (res.data.id) {
      getEquipmentListFun()
      delEquipModelAlert.value = false
      ElMessage({
        message: '删除成功',
        type: 'success',
      })
    }
  }).catch(err => {
    console.log(err);
  })
}


</script>
